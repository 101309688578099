import { AppRoute as Route } from "./config";
import { useEffect } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Init as initializeAnalytics } from "./analytics";
import { useTranslation } from "react-i18next";
import MainLayout from "./pages/layouts/Main";
import HomePage from "./pages/Home";
import TourPage from "./pages/Tour";
import PoiPage from "./pages/Poi";
import BlankPage from "./pages/Blank";

function App() {
  const { t } = useTranslation()

  useEffect(() => {
    initializeAnalytics();
  }, [])

  const router = createBrowserRouter([
    { 
      path: Route.Home, 
      element: <MainLayout/>,
      children: [
        { index: true, element: <HomePage/> },
        { path: Route.Tour, element: <TourPage/> },
        { path: Route.Poi, element: <PoiPage/> },
        { 
          path: Route.Credits, 
          element: 
            <BlankPage 
              metaTitle={t("credits.meta.title")}
              metaDescription={t("credits.meta.description")}
              title={t("credits.title")}
              content={t("credits.content")}
            /> 
        }
      ],
      errorElement:
        <BlankPage 
          metaTitle={t("error.meta.title")}
          metaDescription={t("error.meta.description")}
          title={t("error.title")}
          content={t("error.content")}
        />
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
