import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { getTour, getPoi } from "../utils/JsonParser";
import Poi from "../components/Poi/Poi";

function PoiPage() {
  const { t, i18n } = useTranslation();
  const params = useParams();
  const [tour, setTour] = useState(null);
  const [poi, setPoi] = useState(null);

  useEffect(() => {
    setPoi(getPoi(params.poiId));
    setTour(getTour(params.tourId));
  }, [params.tourId, params.poiId]);

  return (
    <>
      {tour && poi && 
        <>
          <Helmet>
            <title>{`${poi.name[i18n.resolvedLanguage]} | ${t("app.name")}`}</title>
            <meta name="description" content={poi.description[i18n.resolvedLanguage]} />
          </Helmet>
          <Poi poi={poi} tour={tour} />
        </>
      }
    </>
  );
}

export default PoiPage;
