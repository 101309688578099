import { Link } from "react-router-dom";
import { Back as BackIcon } from "./Icons";

function Title(props) {

  return(
    <div id="top">
      <div id="title">
        {props.backLink && 
          <Link id="back" to={props.backLink} >
            <BackIcon />
          </Link> 
        }
        <h1>
          {props.number && <span className="number">{props.number}</span>}
          <span>{props.title}</span>
        </h1>
      </div>
    </div>
  );
}

export default Title;