import { AppRoute as Route } from "../../config";
import { useNavigate, generatePath } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { formatTime } from "../../utils";
import { NextAlt as NextIcon } from "../UI/Icons"

function PoiItem(props) {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const poiClickHandler = () => {
    navigate(generatePath(Route.Poi, {tourId: props.tour.id, poiId: props.poi.id}));
  }

  return (
    <div className="row">
      <div className="col-12 poi-item" onClick={poiClickHandler}>
        <div className="number">
          {props.poi.number}
        </div>
        <div className="title">{props.poi.name[i18n.resolvedLanguage]}</div>
        <div className="length hidden-sm">
          {formatTime(props.poi.length[i18n.resolvedLanguage])}
        </div>
        <span className="next"><NextIcon /></span>
      </div>
    </div>
  );
}

export default PoiItem;
