import { AppRoute as Route } from "../../config";
import { useNavigate, generatePath } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { loadMedia } from "../../utils";
import { Next as NextIcon } from "../UI/Icons"

import headphone from "../../assets/images/ic-headphone.svg";

function TourItem(props) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const path = props.tour.pois.length === 1 ? generatePath(Route.Poi, { tourId: props.tour.id, poiId: props.tour.pois[0].id}) : generatePath(Route.Tour, { tourId: props.tour.id });

  const getTourName = () => {
    const name = props.tour.name[i18n.resolvedLanguage];
    const splitName = name.split(" ");
    return (
      <>
        <span className="font-cursive">{splitName[0]}</span><br />
        <span>{splitName.slice(1).join(" ")}</span>
      </>
    );
  }

  const tourClickHandler = () => {
    if (!props.tour.published) {
      return;
    }
    navigate(path);
  }

  return (
    <div className="col-lg-4">
      <div className={`tour-item tour-${props.tour.id}`} onClick={tourClickHandler} title={!props.tour.published ? t("tour.unpublished") : ""}>
        <div className="header">
          <img
            src={loadMedia(props.tour.image)}
            width="100%"
            alt={props.tour.name[i18n.resolvedLanguage]}
          />
          <img
            className="headphone"
            src={headphone}
            width="100%"
            alt={t("tour.audioguide")}
            />
            <img
              className="character"
              src={loadMedia(props.tour.character.image)}
              alt={props.tour.character.name}
              title={props.tour.character.name}
            />
        </div>
        <div className="description">
          <h2>{getTourName()}</h2>
          <span className="next">
            <NextIcon />
          </span>
        </div>
        {!props.tour.published && <div className="disabled"></div>}
      </div>
    </div>
  );
}

export default TourItem;