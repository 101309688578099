import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Carousel as BCarousel, Modal } from "react-bootstrap";
import { loadMedia } from "../../utils";

function Carousel(props) {
  const { i18n } = useTranslation();
  const [zoomedImage, setZoomedImaged] = useState(null);

  const handleClose = () => setZoomedImaged(null);

  return (
    <>
      <BCarousel
        id={props.id}
        controls={false}
        interval={props.interval}
        indicators={props.images.length > 1}
      >
        {props.images.map((i, index) => {
          return (
            <BCarousel.Item key={`img-${index}`}>
              <img
                src={
                  loadMedia(
                    typeof i.content === 'string' || i.content instanceof String ? 
                      i.content : i.content[i18n.resolvedLanguage]
                  )
                }
                className="d-block w-100"
                alt={i.caption[i18n.resolvedLanguage]}
              />
              <BCarousel.Caption>
                <p>{i.caption[i18n.resolvedLanguage]}</p>
              </BCarousel.Caption>
              {/*<button className="zoom" onClick={() => handleShow(i)}></button>*/}
            </BCarousel.Item>
          );
        })}
      </BCarousel>
      <Modal
        id="zoom-image-modal"
        show={zoomedImage !== null}
        onHide={handleClose}
        centered={true}
      >
        <Modal.Body>
          {zoomedImage ? (
            <img
              src={
                loadMedia(
                  typeof zoomedImage.content === 'string' || zoomedImage.content instanceof String ? 
                    zoomedImage.content : zoomedImage.content[i18n.resolvedLanguage]
                )
              }
              width="100%"
              alt={zoomedImage.caption[i18n.resolvedLanguage]}
            />
          ) : null}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Carousel;
