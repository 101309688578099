import { useTranslation } from "react-i18next";

function Transcription(props) {
  const { i18n } = useTranslation();

  return (
    <div id="transcription">
      <h2 className="hidden-lg">
        {props.poi.name[i18n.resolvedLanguage]}
      </h2>
      <div
        dangerouslySetInnerHTML={{
          __html: props.poi.text[i18n.resolvedLanguage],
        }}
      ></div>
    </div> 
  );
}

export default Transcription;