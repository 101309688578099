import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import DocumentItem from "./DocumentItem";
import Image from "./Image";
import Text from "./Text";

function Documents(props) {
  const { i18n } = useTranslation();
  const [documentSelected, setDocumentSelected] = useState(null);

  return (
    <>
      <div className="documents-list">
        {props.documents.map((document, index) => {
          return <DocumentItem key={`doc-${index}`} document={document} onDocumentClick={() => setDocumentSelected(document)}/>;
        })}
      </div>
      {documentSelected &&
        <Modal
          id="carousel-modal"
          show={documentSelected !== null}
          onHide={() => setDocumentSelected(null)}
          centered={true}
        >
          <Modal.Body>
            {documentSelected.type === "image" &&
              <Image 
                name={documentSelected.name[i18n.resolvedLanguage]}
                content={
                  typeof documentSelected.content === 'string' || documentSelected.content instanceof String ? 
                    documentSelected.content : documentSelected.content[i18n.resolvedLanguage]
                }
                caption={documentSelected.caption &&
                    (typeof documentSelected.caption === 'string' || documentSelected.caption instanceof String ? 
                      documentSelected.caption : documentSelected.caption[i18n.resolvedLanguage])
                }
              />
            }
            {documentSelected.type === "document" &&
              <Text 
                name={documentSelected.name[i18n.resolvedLanguage]}
                content={documentSelected.content[i18n.resolvedLanguage]}
              />
            }
          </Modal.Body>
        </Modal>
      }
    </>
  );
}

export default Documents;
