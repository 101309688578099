import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { getTours } from "../utils/JsonParser";
import Home from "../components/Home";

function HomePage() {
  const { t } = useTranslation();
  const [tours, setTours] = useState([]);

  useEffect(() => {
    setTours(getTours());
  }, [])

  return (
    <>
      <Helmet>
        <title>{`${t("home.meta.title")} | ${t("app.name")}`}</title>
        <meta name="description" content={t("home.meta.description")} />
      </Helmet>
      <Home tours={tours}/>
    </>
  );
}

export default HomePage;
