// import { AppLanguage as supportedLanguages } from "../../config";
import { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import ScrollToTop from "../../hoc/ScrollToTop";
import Header from "../../components/UI/Header";
import Menu from "../../components/UI/Menu";
// import LanguageSwitcher from "../../components/UI/LanguageSwitcher";
import ShareModal from "../../components/Others/Share/ShareModal";
import CookiesConsent from "../../components/Others/CookiesConsent";

function MainLayout() {
  const { pathname } = useLocation();
  const [menuExpanded, setMenuExpanded] = useState(false);
  const [shareModalOpened, setShareModalOpened] = useState(false);

  const PAGE_TRANSITION_TIME = 600; // 0.6 sec

  const toggleMenuHandler = () => {
    setMenuExpanded(!menuExpanded);
  }

  const closeMenuHandler = () => {
    setMenuExpanded(false);
  }

  const openShareModalHandler = () => {
    setMenuExpanded(false);
    setShareModalOpened(true);
  }

  return (
    <ScrollToTop>
      <Header toggleMenu={toggleMenuHandler} menuExpanded={menuExpanded} />
      <TransitionGroup component={null}>
        <CSSTransition
          key={pathname}
          classNames="fade"
          timeout={PAGE_TRANSITION_TIME}
        >
          <main>
            <Menu
              expanded={menuExpanded}
              closeMenu={closeMenuHandler}
              openShareModal={openShareModalHandler}
            />
            <div id="content">
              {/*Object.values(supportedLanguages).length > 1 &&
                <div className="hidden-md">
                  <div className="navbar">
                    <div className="navbar-nav ml-auto">
                      <ul className="d-flex mb-0 list-unstyled">
                        <LanguageSwitcher />
                      </ul>
                    </div>
                  </div>
                </div>
              */}
              <Outlet />
            </div>
          </main>
        </CSSTransition>
      </TransitionGroup>
      <CookiesConsent />
      <ShareModal
        show={shareModalOpened}
        onHide={() => setShareModalOpened(false)}
      />
    </ScrollToTop>
  );
}
export default MainLayout;