import { AppRoute as Route } from "../../config";
import { useState, useRef, useEffect } from "react";
import { useNavigate, generatePath } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getNextPoi, getPreviousPoi } from "../../utils/JsonParser";
import { loadMedia, formatTime } from "../../utils";
import { PoiAudioPlay, PoiAudioPause, PoiAudioListened } from "../../analytics";
import { Play as PlayIcon, Pause as PauseIcon } from "../UI/Icons";

function PlayerAudio(props) {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [duration, setDuration] = useState(formatTime(0));
  const [isPlaying, setIsPlaying] = useState(false);
  const [wasPlaying, setWasPlaying] = useState(false);
  const [previousPoi, setPreviousPoi] = useState(null);
  const [nextPoi, setNextPoi] = useState(null);
  const [analyticsItem, setAnalyticsItem] = useState({});
  const currentTimeRef = useRef(null);
  const seekRef = useRef(null);
  const audioRef = useRef(null);

  useEffect(() => {
    setAnalyticsItem({
      id: props.poi.id,
      name: `${i18n.resolvedLanguage}-${props.poi.name[i18n.resolvedLanguage]}`
    });
    setPreviousPoi(getPreviousPoi(props.tour.id, props.poi.id));
    setNextPoi(getNextPoi(props.tour.id, props.poi.id));
    resetAudio();
  }, [props.tour, props.poi, i18n.resolvedLanguage]);

  const goToPreviousPoi = () => {
    navigate(generatePath(Route.Poi, { tourId: props.tour.id, poiId: previousPoi.id }));
  }

  const goToNextPoi = () => {
    navigate(generatePath(Route.Poi, { tourId: props.tour.id, poiId: nextPoi.id }));
  }

  const initializeAudio = () => {
    const duration = audioRef.current.duration;
    const audioDuration = (isNaN(duration) || duration === Infinity) ? 
      props.poi.length[i18n.resolvedLanguage] : Math.round(duration);
    seekRef.current.setAttribute('max', audioDuration);
    setDuration(formatTime(audioDuration));
  }

  const resetAudio = () => {
    currentTimeRef.current.innerHTML = formatTime(0);
    seekRef.current.value = 0;
    setDuration(formatTime(0));
    setIsPlaying(false);
    audioRef.current.load();
  }

  const togglePlay = () => {
    if (audioRef.current.paused || audioRef.current.ended) {
      audioRef.current.play();
      PoiAudioPlay(analyticsItem);
    } else {
      audioRef.current.pause();
      PoiAudioPause(analyticsItem);
    }
  }

  const playerStateDidUpdate = () => {
    setIsPlaying(!audioRef.current.paused);
  }

  const updateProgress = () => {
    seekRef.current.value = audioRef.current.currentTime;
    currentTimeRef.current.innerHTML = formatTime(audioRef.current.currentTime);
  }

  const skipAheadDidBegin = () => {
    if (!audioRef.current.paused) {
      setWasPlaying(true);
      audioRef.current.pause();
    }
  }

  const skipAheadDidFinish = (event) => {
    const skipTo = event.target.dataset.seek ? event.target.dataset.seek : event.target.value;
    audioRef.current.currentTime = skipTo;
    seekRef.current.value = skipTo;
    if (wasPlaying) {
      audioRef.current.play();
      setWasPlaying(false);
    }
  }

  const audioDidFinish = () => {
    currentTimeRef.current.innerHTML = formatTime(0);
    /*if (AppConfig.SaveSessionProgress) {
      sessionStorage.removeItem(this.state.sessionCurrentTimeKey);
    }*/
    PoiAudioListened(analyticsItem);
  }

  return (
    <div id="player">
      <div className="info">
        <div className="hidden-md w-100">
          <div className="track">
            <h2>{`${props.poi.number}. ${props.poi.name[i18n.resolvedLanguage]}`}</h2>
          </div>
        </div>
        <div className="timeline">
          <span ref={ currentTimeRef } className="current-time">
            {formatTime(0)}
          </span>
          <input
            ref={ seekRef }
            className="seek"
            defaultValue="0"
            min="0"
            type="range"
            step="1"
            onPointerDown={ skipAheadDidBegin }
            onPointerUp={ skipAheadDidFinish }
          />
          <span className="duration">{ duration }</span>
        </div>
      </div>
      <div className="controls">
        <audio
          ref={ audioRef }
          src={loadMedia(props.poi.audio[i18n.resolvedLanguage])}
          title={props.poi.name[i18n.resolvedLanguage]}
          onLoadedMetadata={ initializeAudio }
          onPlay={ playerStateDidUpdate }
          onPause={ playerStateDidUpdate }
          onEnded={ audioDidFinish }
          onTimeUpdate={ updateProgress }
          controls={false}
          preload="metadata"
          autoPlay={false}
          muted={false}
          loop={false}
        />
        <button
          onClick={goToPreviousPoi}
          className="previous"
          disabled={previousPoi ? false : true}
        >
          { previousPoi ? `#${previousPoi.number}` : null }
        </button>
        <button onClick={togglePlay} className="play">
          { isPlaying ? <PauseIcon /> : <PlayIcon />}
        </button>
        <button
          onClick={goToNextPoi}
          className="next"
          disabled={nextPoi ? false : true}
        >
          { nextPoi ? `#${nextPoi.number}` : null}
        </button>
      </div>
    </div>
  );
}

export default PlayerAudio;
