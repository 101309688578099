import { AppRoute as Route } from "../../config";
import { Link, generatePath } from "react-router-dom";
import { useTranslation } from "react-i18next";
import iconHome from '../../assets/images/ic-home.svg';

function Breadcrumb(props) {
  const { t } = useTranslation();

  return (
    <div id="breadcrumb" className="w-100">
      <ul>
        <li>
          <img src={iconHome} alt={t("menu.home")} />
        </li>
        <li>
          <Link to={generatePath(Route.Home)}>{t("menu.home")}</Link>
        </li>
        {props.children}
      </ul>
    </div>
  );
}

export default Breadcrumb;
