import { AppRoute as Route } from "../../config";
import { Navbar } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

function Header(props) {
  const { pathname } = useLocation();

  const logoClassname = pathname !== Route.Home
      ? "hidden"
      : null;

  return (
    <header>
      <div className="hidden-lg">
        <Navbar
          expand="lg"
          onToggle={props.toggleMenu ?? null}
          expanded={props.menuExpanded ?? null}
        >
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
        </Navbar>
        <Link
          id="logo"
          className={logoClassname}
          to={Route.Home}
        />
      </div>
    </header>
  );
}

export default Header;
