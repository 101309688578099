import { AppRoute as Route } from "../config";
import { generatePath } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import Title from "../components/UI/Title";
import Breadcrumb from "../components/UI/Breadcrumb";

function BlankPage(props) {
  const { t } = useTranslation();

  return (
    <div className="scroll-wrapper">
      <Helmet>
        <title>{`${props.metaTitle} | ${t("app.name")}`}</title>
        <meta name="description" content={props.metaDescription} />
      </Helmet>
      <Breadcrumb />
      <Title title={props.title} backLink={generatePath(Route.Home)} />
      <section className="page-header">
      </section>
      <div className="container-fluid pt-3" dangerouslySetInnerHTML={{__html:props.content}}></div>
    </div>
  );
}

export default BlankPage;
