export const formatTime = (seconds) => {

  if (isNaN(seconds) || seconds === Infinity) {
    return '0:00';
  }
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.round(seconds % 60);
  return [
    m,
    s > 9 ? s : '0' + s
  ].join(':');
};

export const loadMedia = (media) => {

  try {
    if (media && !isURL(media))
      return require('../assets/medias/' + media);
  } catch (e) {
    if (process.env.NODE_ENV !== 'production') {
      console.error("File not found ", media, e);
    }
  }
  return media;
};

export const isURL = (string) => {
  return string.startsWith("http");
};