import { useTranslation } from "react-i18next";
import TourItem from "./Tour/TourItem";
import { loadMedia } from "../utils";

function Home(props) {
  const { t } = useTranslation();

  return (
    <div id="home" className="scroll-wrapper">
      <section className="page-header">
        <img 
          className="character" 
          src={loadMedia("characters.png")} 
          alt="Enguerrand, Eudes de Châteauroux, Viollet le Duc, Eudes de Déols" 
          title="Enguerrand, Eudes de Châteauroux, Viollet le Duc, Eudes de Déols" 
        />
      </section>
      <div className="container-fluid">
        <h1>
          <span className="font-cursive font-color-1">
            <small>{t("home.title.row1")}</small>
          </span>
          <br />
          <span className="font-color-2 unbreakable-text">
            <small>{t("home.title.row2")}</small>
          </span>
        </h1>
        <div id="tours-list" className="row">
          {props.tours.map((tour) => {
            return <TourItem key={tour.id} tour={tour} />;
          })}
        </div>
      </div>
      <footer>
        <img src={loadMedia("logo-unesco-compostelle.png")} height="60px" alt="UNESCO - Saint-Jacques de Compostelle" />
        <img src={loadMedia("logo-berry.jpg")} height="60px" alt="Berry" />
      </footer>
    </div>
  );
}

export default Home;
