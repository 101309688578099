import { 
  AppLanguage as supportedLanguages, 
  AppDefaultLanguage as defaultLanguage
} from '../config';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: false,
    fallbackLng: defaultLanguage,
    supportedLngs: Object.values(supportedLanguages),
  });

export default i18n;
