import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from "@fortawesome/free-regular-svg-icons"
import { faXTwitter, faFacebook } from "@fortawesome/free-brands-svg-icons"

function ShareModal(props) {
  const { t } = useTranslation();

  return (
    <>
      <Modal id="share-modal"
          show={props.show}
          onHide={props.onHide}
          centered={true}
        >
          <Modal.Header>
            <h5 className="modal-title text-uppercase">
              <i className="ic ic-share"></i>
              { t("menu.share") }
            </h5>
          </Modal.Header>
          <Modal.Body>
            <div>
              <a
                href={ t("share.twitter") }
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faXTwitter} size="2x" />
                <p>Twitter</p>
              </a>
            </div>
            <div>
              <a
                href={ t("share.facebook") }
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faFacebook} size="2x" />
                <p>Facebook</p>
              </a>
            </div>
            <div>
              <a
                href={ t("share.email") }
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faEnvelope} size="2x" />
                <p>Email</p>
              </a>
            </div>
          </Modal.Body>
        </Modal>
    </>
  )
}

export default ShareModal;