import { AppRoute as Route } from "../../config";
import { useEffect } from "react";
import { Link, generatePath } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Title from "../UI/Title";
import Breadcrumb from "../UI/Breadcrumb";
import Carousel from "./Carousel";
import PlayerAudio from "./PlayerAudio";
import Transcription from "./Transcription";
import Documents from "./Documents/Documents";

function Poi(props) {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.getElementById('tab-list-0').querySelector('a').click();
  }, [props.poi]);

  const Header = () => {
    return (
      <>
        <Breadcrumb>
          <li>
            <Link to={generatePath(Route.Tour, { tourId: props.tour.id })}>
              {props.tour.name[i18n.resolvedLanguage]}
            </Link>
          </li>
          <li>{props.poi.name[i18n.resolvedLanguage]}</li>
        </Breadcrumb>
        <Title
          title={props.poi.name[i18n.resolvedLanguage]}
          number={`${props.poi.number}`}
          backLink={generatePath(Route.Tour, { tourId: props.tour.id })}
        />
      </>
    );
  };

  return (
    <div id="poi" className={`container-fluid scroll-wrapper tour-${props.tour.id}`}>
      <div className="hidden-lg row">
        <Header />
        <div id="medias-md" className="medias-container">
          <Carousel className="poi-carousel" images={props.poi.images} interval={null} />
        </div>
      </div>
      <PlayerAudio poi={props.poi} tour={props.tour} />
      <b id="tab-0" className="targets"></b>
      <b id="tab-1" className="targets"></b>
      <div id="tabs-container" className="row hidden-lg">
        <ul>
          <li id="tab-list-0">
            <a href="#tab-0">{t("poi.transcription")}</a>
          </li>
          {props.poi.documents.length > 0 ?
            <li id="tab-list-1">
              <a href="#tab-1">{t("poi.more")}</a>
            </li>
          :
            null
          }
        </ul>
      </div>
      <div id="tabs-content" className="row">
        <div id="tab-content-0" className="col-lg-6 col-12">
          <div className="hidden-md row">
            <Header />
          </div>
          <Transcription poi={props.poi} />
        </div>
        <div id="tab-content-1" className="col-lg-6 col-12">
          <div className="hidden-md">
            <div id="medias-lg" className="medias-container">
              <Carousel id="poi-carousel" images={props.poi.images} interval={null} />
            </div>
          </div>
          <Documents documents={props.poi.documents} />
        </div>
      </div>
    </div>
  );
}

export default Poi;