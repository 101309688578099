import { loadMedia } from "../../../utils";

function Image(props) {
  return (
    <>
      <img src={loadMedia(props.content)} width="100%" alt="" />
      <p className="caption">
        {props.name}
        {props.caption &&
          <>
            <br />
            <small dangerouslySetInnerHTML={{__html: props.caption}}></small>
          </>
        }
      </p>
    </>
  )
}

export default Image;