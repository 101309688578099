import { AppPreference } from '../../../config';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Init as initializeAnalytics } from '../../../analytics';

const CookiesConsent = (props) => {
  const { t } = useTranslation();

  const [hidden, setHidden] = useState(localStorage.getItem(AppPreference.CookiesConsent) !== null);

  const setCookiesConsent = (value) => {
    setHidden(true);
    localStorage.setItem(AppPreference.CookiesConsent, value.toString());
    if (value === true) {
      initializeAnalytics();
    }
  };

  return (
    !hidden ? 
      <div id="cookies-consent">
        <p>
          { t("consent.msg") } 
          <br />
          <button className="btn btn-success btn-small" onClick={() => setCookiesConsent(true)}>{ t("consent.ok") }</button>
          <button className="btn btn-danger btn-small" onClick={() => setCookiesConsent(false)}>{ t("consent.no") }</button>
        </p>
      </div>
    :
      null
  );
}

export default CookiesConsent;