import { AppLanguage as supportedLanguages } from "../../config";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

function LanguageSwitcher(props) {
  const { t } = useTranslation();

  return (
    Object.keys(supportedLanguages).map((key, index) => {
      const locale = supportedLanguages[key];
      const flag =
        i18n.resolvedLanguage !== locale ? (
          <li key={supportedLanguages[key]} className={props.itemClass}>
            <button key={supportedLanguages[key]} type="button" title={t(`menu.language.${locale}`)} onClick={() => i18n.changeLanguage((locale))}>
              <i className={`ic flag-${locale}`}></i>
              {props.withLabel && t(`menu.language.${locale}`)}
            </button>
          </li>
        ) : null;
      return flag;
    })
  )
}

export default LanguageSwitcher;