export const Back = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13.949" height="24.441" viewBox="0 0 13.949 24.441">
      <g id="back" transform="translate(-24.086 -16.086)" opacity="1">
        <line id="Ligne_1" data-name="Ligne 1" y1="11" x2="11.121" transform="translate(25.5 17.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="2"/>
        <line id="Ligne_3" data-name="Ligne 3" x2="11.121" y2="10.613" transform="translate(25.5 28.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="2"/>
      </g>
    </svg>
  );
}

export const Next = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13.949" height="24.441" viewBox="0 0 13.949 24.441">
      <g id="back" transform="translate(38.035 40.527) rotate(180)">
        <line id="Ligne_1" data-name="Ligne 1" y1="11" x2="11.121" transform="translate(25.5 17.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="2"/>
        <line id="Ligne_3" data-name="Ligne 3" x2="11.121" y2="10.613" transform="translate(25.5 28.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="2"/>
      </g>
    </svg>
  );
}

export const NextAlt = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
      <g id="player" transform="translate(-338 -364)">
        <circle id="Ellipse_9" data-name="Ellipse 9" cx="13" cy="13" r="13" transform="translate(338 364)" fill="currentColor"/>
        <path id="play" d="M1443,417.359v14.563l10.388-6.849Z" transform="translate(-1095.79 -47.859)" stroke="currentColor" fill="#fff"/>
      </g>
    </svg>
  );
}

export const Play = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23.329" height="32.703" viewBox="0 0 23.329 32.703">
      <path id="play" d="M1443,417.359v32.7l23.329-15.382Z" transform="translate(-1443.004 -417.359)" fill="currentColor"/>
    </svg>
  );
}

export const Pause = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="32" viewBox="0 0 22 32">
      <g id="Pause" transform="translate(-175 -144)">
        <rect id="Rectangle_80" data-name="Rectangle 80" width="9" height="32" transform="translate(175 144)" fill="currentColor"/>
        <rect id="Rectangle_81" data-name="Rectangle 81" width="9" height="32" transform="translate(188 144)" fill="currentColor"/>
      </g>
    </svg>
  );
}

export const Document = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 384 512">
      <path fill="currentColor" d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm64 236c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8zm0-64c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8zm0-72v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm96-114.1v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z"></path>
    </svg>
  );
}

export const Image = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="20" viewBox="0 0 25 20">
      <path id="icone_photo" data-name="icone photo" d="M25,20H0V0H25V20ZM12.5,3.75A6.25,6.25,0,1,0,18.749,10,6.258,6.258,0,0,0,12.5,3.75ZM20,2.5V5h2.5V2.5Z" fill="currentColor"/>
    </svg>
  );
}

export const Audio = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18.643" height="18" viewBox="0 0 18.643 18">
      <g id="icone_audio" data-name="icone audio" transform="translate(-307.902 -406)">
        <path id="Union_2" data-name="Union 2" d="M-1673.488,15H-1680V9.4l-.1-.084.1-.09V3h6.748L-1670,0V18Z" transform="translate(1988 406)" fill="currentColor"/>
        <path id="Tracé_89" data-name="Tracé 89" d="M2008,369s2.039.743,2,3.964-2,3.681-2,3.681" transform="translate(-1688 42.496)" fill="none" stroke="currentColor" strokeWidth="1"/>
        <path id="Tracé_91" data-name="Tracé 91" d="M2008,369s4.124,1.5,4.044,8.017-4.044,7.446-4.044,7.446" transform="translate(-1686 38.5)" fill="none" stroke="currentColor" strokeWidth="1"/>
      </g>
    </svg>

  );
}

export const Video = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23.63" height="16" viewBox="0 0 23.63 16">
      <path id="icone_video" data-name="icone video" d="M-1685,16V0h18V3.376l5.368-3,.262,15-5.63-2.89V16Z" transform="translate(1685)" fill="currentColor"/>
    </svg>
  );
}