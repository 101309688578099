import { AppPreference } from '../config/AppPreferences';
import { createInstance } from "@jonkoops/matomo-tracker-react";

let analytics = null;
const analyticsEnabled = process.env.NODE_ENV === 'production';
const analyticsIsAvailable = () => {

  return analyticsEnabled && localStorage.getItem(AppPreference.CookiesConsent) === 'true';
}

export const Init = () => {

  if (!analyticsIsAvailable()) return;

  analytics = createInstance({
    urlBase: "https://analytics.cheztoi.eu/",
    siteId: 10,
    userId: undefined, // optional, default value: `undefined`.
    trackerUrl: "https://analytics.cheztoi.eu/matomo.php", // optional, default value: `${urlBase}matomo.php`
    srcUrl: "https://analytics.cheztoi.eu/matomo.js", // optional, default value: `${urlBase}matomo.js`
    disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
    heartBeat: {
      // optional, enabled by default
      active: true, // optional, default value: true
      seconds: 10, // optional, default value: `15
    },
    linkTracking: true, // optional, default value: true
    configurations: {
      // optional, default value: {}
      // any valid matomo configuration, all below are optional
      disableCookies: true,
      setSecureCookie: process.env.NODE_ENV === "production",
      setRequestMethod: "POST",
    },
  });
}

export const PoiAudioPlay = (item) => {

  if (!analyticsIsAvailable() || !analytics) return;

  analytics.trackEvent({
    category: "poi_audio_played",
    action: "click-event",
    name: item.name,
    customDimensions: [
      {
        id: item.id,
      },
    ],
  });
}

export const PoiAudioPause = (item) => {

  if (!analyticsIsAvailable() || !analytics) return;

  analytics.trackEvent({
    category: "poi_audio_paused",
    action: "click-event",
    name: item.name,
    customDimensions: [
      {
        id: item.id,
      },
    ],
  });
}

export const PoiAudioListened = (item) => {

  if (!analyticsIsAvailable() || !analytics) return;

  analytics.trackEvent({
    category: "poi_audio_listened",
    action: "click-event",
    name: item.name,
    customDimensions: [
      {
        id: item.id,
      },
    ],
  });
}