import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { usePrevious } from "../hooks/";

function ScrollToTop(props) {
	const { pathname } = useLocation();
	const previousPathname = usePrevious(pathname);

	useEffect(() => {
		if (previousPathname !== pathname) {
			window.scrollTo(0, 0);
			const scrollWrapper = document.querySelector('.scroll-wrapper');
			if (scrollWrapper) {
				scrollWrapper.scrollTo(0, 0);
			}
		}
	}, [previousPathname, pathname]);

	return (
		<>
			{props.children}
		</>
	)
}

export default ScrollToTop;