import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { getTour } from "../utils/JsonParser";
import Tour from "../components/Tour/Tour";

function TourPage() {
  const { t, i18n } = useTranslation();
  const params = useParams();
  const [tour, setTour] = useState(null);

  useEffect(() => {
    setTour(getTour(params.tourId));
  }, [params.tourId])

  return (
    <>
      {tour &&
        <>
          <Helmet>
            <title>{`${tour.name[i18n.resolvedLanguage]} | ${t("app.name")}`}</title>
            <meta name="description" content={tour.description[i18n.resolvedLanguage]} />
          </Helmet>
          <Tour tour={tour} />
        </>
      }
    </>
  );
}

export default TourPage;
