import { AppRoute as Route } from "../../config";
import { NavLink, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Logo from "./Logo";
import LanguageSwitcher from "./LanguageSwitcher";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopyright } from "@fortawesome/free-regular-svg-icons"

function Menu(props) {
  const { t } = useTranslation();

  return (
    <nav id="navbar-left-menu" className={`collapse ${props.expanded ? "show" : ""}`}>
      <Link to={ Route.Home } className="navbar-brand hidden-md" onClick={props.closeMenu ?? null}>
        <Logo />
      </Link>
      <div id="left-menu">
        <ul>
          <li className="menu-item">
            <NavLink
              onClick={props.closeMenu ?? null}
              to={ Route.Home }
              className={({ isActive }) => (isActive ? "active" : undefined)}
              end
            >
              <i className="ic ic-tours"></i>
              { t("menu.home") }
            </NavLink>
          </li>
          <li className="menu-item">
            <button onClick={props.openShareModal ?? null}>
              <i className="ic ic-share"></i>
              { t("menu.share") }
            </button>
          </li>
          <li className="menu-item">
            <NavLink
              onClick={props.closeMenu ?? null}
              to={ Route.Credits }
              className={({ isActive }) => (isActive ? "active" : undefined)}
            >
              <i className="ic"><FontAwesomeIcon icon={faCopyright} /></i>
              { t('menu.credits') }
            </NavLink>
          </li>
          <LanguageSwitcher itemClass="menu-item" withLabel={true} /> {/* hidden-lg */}
        </ul>
      </div>
    </nav>
  );
}

export default Menu;
