import JSPath from 'jspath';

import app from '../assets/app.json';

export const getTours = () => {

  const tours = JSPath.apply('.tours', app);
  if (!tours) {
    throw new Error("Page not found", {status: 404});
  }
  return tours;
}

export const getTour = (id) => {

  const res = JSPath.apply('.tours{.id == ' + id + '}', app);
  const tour = res.length === 1 ? res[0] : null;
  if (!tour || !tour.published) {
    throw new Error("Page not found", {status: 404});
  }
  return tour;
}

export const getPoi = (id) => {

  const res = JSPath.apply('.tours.pois{.id == ' + id + '}', app);
  const poi = res.length === 1 ? res[0] : null;
  if (!poi) {
    throw new Error("Page not found", {status: 404});
  }
  return poi;
}

export const getPreviousPoi = (tourId, poiId) => {
  
  try {
    const pois = JSPath.apply('.tours{.id == ' + tourId + '}.pois', app);
    const currentPoiIndex = pois.findIndex(p => {
      return p.id === poiId;
    });
    return currentPoiIndex > 0 ? pois[(currentPoiIndex-1)] : null;
  } catch (error) {
    return null;
  }
}

export const getNextPoi = (tourId, poiId) => {

  try {
    const pois = JSPath.apply('.tours{.id == ' + tourId + '}.pois', app);
    const currentPoiIndex = pois.findIndex(p => {
      return p.id === poiId;
    });
    return currentPoiIndex < (pois.length-1) ? pois[(currentPoiIndex+1)] : null;
  } catch (error) {
    return null;
  }
}